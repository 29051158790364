var myMap;
ymaps.ready(init);

function init() {
  myMap = new ymaps.Map(
    "map",
    {
      center: [53.862982, 27.655328],
      zoom: 12.5,
      controls: ["zoomControl"],
    },
    {
      searchControlProvider: "yandex#search",
    }
  );

  // var myPlacemark = new ymaps.Placemark([53.862982, 27.655328], {}, {
  //     iconLayout: 'default#image',
  //     iconImageHref: './images/mt-logo-darl.jpg',
  //     iconImageSize: [30, 42],
  //     iconImageOffset: [-3, -42]
  // });

  myGeoObject = new ymaps.GeoObject(
    {
      // Описание геометрии.
      geometry: {
        type: "Point",
        coordinates: [53.862982, 27.655328],
      },
      // Свойства.
      properties: {
        // Контент метки.
        iconContent: "<strong>MUAYTHAI FACTORY</strong>",
        hintContent: "We are one team",
      },
    },
    {
      // Опции.
      // Иконка метки будет растягиваться под размер ее содержимого.
      preset: "islands#blackStretchyIcon",
      // Метку можно перемещать.
      draggable: false,
    }
  );

  myMap.geoObjects.add(myGeoObject);

  myMap.behaviors.disable(["scrollZoom"]).enable("ruler");
}
